import React from 'react';
import styled from '@emotion/styled';
import ContactInfo from './ContactInfo';
import ContactForm from './ContactForm';

const ClearFix = styled('div')`
  clear: both;
`;

// REFACTOR
// Div here just for fun?
const ContactAll = () => (
  <div>
    <ContactInfo />
    <ContactForm />
    <ClearFix />
  </div>
);

export default ContactAll;
