import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import { wrapper, breakpoints, ContentStyles, border } from '../../utils/style';
import ContactAll from '../../components/Content/Contact/ContactAll';
import { showContent } from '../../components/shared/ContentWithFluidImages';
import RegistrationFormStatic from '../../components/Auth/Form/RegistrationFormStatic';
import { getFile, isMobile } from '../../utils/system';
import IcomoonIcon from '../../components/shared/IcomoonIcon';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import RecentPosts from '../../components/Content/RecentPosts';
import Auth from '../../components/Auth/Auth';
import CookieDisableComponent from '../../components/shared/CookieDisableError';

const FeaturedWrapper = styled('div')`
  position: relative;
  margin: 15px 0;

  .breadcrumbs {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 1;
  }

  & .title {
    pointer-events: none;
    width: 40%;
    height: 100%;
    padding-left: 37px;

    display: flex;
    align-items: center;

    position: absolute;
    top: 0;
    left: 0;

    & > h1 {
      margin-left: 25px;
      margin-bottom: 0;
      font-family: Kanit;
      font-size: 44px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
  }

  .gatsby-image-wrapper {
    border-radius: ${border.borderRadius};
  }

  @media (max-width: ${breakpoints.md}) {
    margin: 0 0;

    h1 {
      position: static;
      margin-top: 20px;
    }

    & .title {
      width: max-content;
      padding-left: 14px;
      padding-top: 30px;
      & > h1 {
        font-size: 24px;
        margin: 0 0 0 9px;
      }
    }

    .breadcrumbs {
      top: 16px;
      left: 16px;
      margin-bottom: 0;
    }
  }

  img {
    border-radius: ${border.borderRadius};
  }
`;

const NoFeaturedWrapperReg = styled('div')`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  border-radius: ${border.borderRadius};
  margin: 40px 0;

  .top-block {
    width: 100%;
  }
  .top-block-right {
    flex-basis: 58%;
  }
  .auth-container {
    height: 50px;
    .auth-wrapper {
      text-align: left;
      width: auto;
      margin: 10px 0;
    }
    .button {
      margin-left: 0;
    }
  }
  @media (max-width: ${breakpoints.lg}) {
    .top-block {
      flex-basis: 100%;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    padding: 0 0;
    margin: 0 0;

    & .top-block {
      margin-top: 48px;
    }
  }
`;

const ContentWrapper = styled('div')`
  ${wrapper};
  ${ContentStyles};

  margin-top: 55px;
  padding-bottom: 55px;
  border-bottom: 1px solid #2f3551;

  .content-casino {
    figure {
      flex-basis: 25%;
    }
    dl {
      flex-basis: 74%;
    }
  }

  & p,
  & li {
    font-family: Kanit;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
  }

  @media (max-width: ${breakpoints.md}) {
    padding-bottom: 35px;
    margin-top: 35px;
  }

  @media (max-width: ${breakpoints.sm}) {
    .content-casino {
      figure,
      dl {
        flex-basis: 100%;
      }
    }
  }
`;

const Bg = styled('div')`
  margin-top: 60px;

  & > div {
    margin-top: 0;
    & > div {
      margin-top: 0;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    margin-top: 35px;
  }
`;

const PageTemplate = ({ data, pageContext }) => {
  const [state, setState] = useState({
    isMobile: false,
  });
  const onResize = () => setState((prevState) => ({ ...prevState, isMobile: isMobile() }));

  useEffect(() => {
    window.addEventListener('resize', onResize);

    setState((prevState) => ({
      ...prevState,
      isMobile: isMobile(),
    }));

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const { wordpressPage: currentPage, allWordpressWpMedia } = data;
  const { edges: media } = allWordpressWpMedia;
  const { featured_media, yoast_meta, title, wordpress_id } = currentPage;
  const { acf } = currentPage;
  const { mobile_banner } = acf;
  const { yoast_wpseo_title, yoast_wpseo_metadesc } = yoast_meta;
  const { alt_text } = featured_media;
  const { breadcrumbs, related: relatedIds } = pageContext;
  const metaTitle = (yoast_meta && yoast_wpseo_title) || `${title}- ${process.env.OWNER}`;
  const metaDesc = yoast_wpseo_metadesc || '';
  const contactPageWPID = process.env.CONTACT_PAGE_WP_ID;
  const [localError, setLocalError] = useState(false);

  useEffect(() => {
    try {
      if (window.localStorage) {
        setLocalError(false);
      }
    } catch (error) {
      setLocalError(true);
    }
  });

  if (!localError) {
    return (
      <Layout>
        <SEO title={metaTitle} description={metaDesc} pathname={currentPage.path} breadcrumbs={breadcrumbs} />
        <>
          {getFile(featured_media) && getFile(mobile_banner) ? (
            <div>
              <FeaturedWrapper>
                <Img
                  fluid={state.isMobile ? getFile(mobile_banner) : getFile(featured_media)}
                  alt={alt_text || title}
                  fadeIn={false}
                  loading="eager"
                />
                <Breadcrumbs breadcrumbs={breadcrumbs} page_title={currentPage.title} />
                <div className="title">
                  <IcomoonIcon icon="register-banner-icon" size={state.isMobile ? 34 : 55} />
                  <h1>{currentPage.title}</h1>
                </div>
              </FeaturedWrapper>
              <NoFeaturedWrapperReg>
                <div className="top-block">
                  <RegistrationFormStatic />
                </div>
              </NoFeaturedWrapperReg>
            </div>
          ) : (
            <NoFeaturedWrapperReg>
              <div className="top-block">
                <h1>{title}</h1>
                <p>เล่นเกมใหม่ฟรี</p>
                <div className="auth-container">
                  <Auth hideRegButton />
                </div>
              </div>
              <div className="top-block top-block-right">
                <RegistrationFormStatic />
              </div>
            </NoFeaturedWrapperReg>
          )}

          <ContentWrapper>
            {wordpress_id === +contactPageWPID && <ContactAll />}
            {showContent(currentPage.content, media)}
          </ContentWrapper>

          {relatedIds.length && (
            <Bg>
              <RecentPosts />
            </Bg>
          )}
        </>
      </Layout>
    );
  }

  return (<CookieDisableComponent />);
};

export default PageTemplate;

export const pageQuery = graphql`
  query($id: String!, $related: [Int], $contentImages: [Int]) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_template
        mobile_banner {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    allWordpressPost(filter: { wordpress_id: { in: $related } }, limit: 4) {
      edges {
        node {
          slug
          title
          excerpt
          wordpress_id
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    allWordpressWpMedia(filter: { wordpress_id: { in: $contentImages } }) {
      edges {
        node {
          wordpress_id
          alt_text
          source_url
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1400) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                presentationWidth
              }
            }
          }
        }
      }
    }
  }
`;
