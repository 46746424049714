/* eslint-disable no-param-reassign */
import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { Formik, Field, Form } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import { color, formStyles, breakpoints, border, formsAutoFillingStylesBlack, font } from '../../../utils/style';
import StoreContext from '../../shared/Context/StoreContext';
import { freespinRegiter } from '../../../utils/api/freespin';
import dictionary from '../../../utils/dictionary';

const Wrapper = styled('div')`
  ${formStyles};
  ${formsAutoFillingStylesBlack};
  position: relative;
  margin-top: -10px;
  text-align: left;
  border-bottom: 1px solid #2f3551;
  padding-bottom: 60px;

  h5 {
    color: #b6bbd3;
    margin-bottom: 20px;
  }
  .selectGame {
    background: none;
    input {
      background: none;
    }
  }
  div[class*='control'] {
    background: none !important;
    outline: none;
    height: 48px;
    > div {
      height: 48px;
    }
  }
  div[class*='placeholder'] {
    top: 50%;
  }
  div[class*='menu'] {
    background-color: ${color.darkPurple}!important;
    > div {
      color: #b6bbd3;

      > div {
        cursor: pointer;
        transition-duration: 0.4s;
      }
      > div:hover,
      > div:focus {
        background: #3f4563;
      }
    }
  }
  .two-fields {
    .fieldWrap {
      width: 48%;
      height: 72px;
      display: inline-block;
      vertical-align: top;
      &:first-of-type {
        margin-right: 4%;
      }

      & input {
        font-family: Kanit;
        font-size: 16px;
        font-weight: ${font.normalWeight};
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: #ffffff;
        padding-left: 13px;
        height: 48px;

        &::-webkit-input-placeholder {
          color: rgba(255, 255, 255, 0.5);
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          transition: color 9999s ease-out, background-color 9999s ease-out;
          transition-delay: 9999s;
        }
      }

      & .selectGame > div,
      & input {
        border: solid 1px rgba(255, 255, 255, 0.4);
        border-radius: ${border.borderRadius};
      }

      & .selectGame {
        & path,
        span {
          fill: rgba(255, 255, 255, 0.4);
          background-color: rgba(255, 255, 255, 0.4);
        }

        & div {
          outline: none;

          &::-webkit-scrollbar {
            width: 0;
            background: transparent;
          }
        }

        & > div {
          & > div {
            & > div {
              height: 100%;
              display: flex;
              align-items: center;
              margin-left: 5px;

              font-family: Kanit;
              font-size: 16px;
              font-weight: ${font.normalWeight};
              font-stretch: normal;
              font-style: normal;
              line-height: 1.38;
              letter-spacing: normal;
              color: rgba(255, 255, 255, 0.5);

              & + div {
              }
            }

            & span {
              & + div {
                cursor: pointer;
                margin: 0;
                position: relative;
              }
            }
          }
        }
      }
    }
  }
  .red-button {
    width: 150px;
    height: 44px;
    border-radius: ${border.borderRadius};
    background-color: #fb0250 !important;
    margin-top: 29px;

    &:disabled {
      opacity: 0.5;
    }

    &:hover {
      background-color: #ff1861 !important;
    }
  }
  .button {
    svg {
      margin-left: 5px;
    }

    &:disabled {
      svg {
        opacity: 0.5;
      }
    }
  }
  & .small-text {
    margin-top: 15px;
    font-family: Kanit;
    font-size: 16px;
    font-weight: ${font.normalWeight};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
  }
  & .minimal-custom-checkbox {
    & label {
      font-family: Kanit;
      font-size: 16px;
      font-weight: ${font.normalWeight};
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    padding-bottom: 35px;

    & .small-text {
      margin-top: 30px;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .two-fields {
      .fieldWrap {
        width: 100%;
        padding-bottom: 10px;
        &:first-of-type {
          margin-right: 0;
        }
      }
    }
  }
`;

const MsgWrap = styled('div')`
  span {
    display: none;
    background-color: ${color.yellow};
    color: ${color.black};
    padding: 5px 10px;
    text-align: center;
    &.visible {
      display: block;
    }
  }
`;

const GamesWrap = styled('div')`
  h4 {
    font-size: 26px;
    color: #28b7fa;
    margin: 0 0 20px;
  }
`;

const showErrorMsg = (msg) => {
  const errorMsg = document.getElementById('registrationErrorMsgPage');
  if (msg) errorMsg.innerText = msg;
  errorMsg.classList.add('visible');
};

const showSuccessMsg = (msg) => {
  const successMsg = document.getElementById('registrationSuccessMsgPage');
  if (msg) successMsg.innerText = msg;
  successMsg.classList.add('visible');
};

const onSubmit = (values, { resetForm, setSubmitting }) => {
  freespinRegiter(values)
    .then((json) => {
      if (json.result) {
        resetForm({});
        showSuccessMsg();
      } else {
        showErrorMsg(json.message);
        setSubmitting(false);
      }
    })
    .catch((err) => {
      console.error('Registration API error: ', err);
    });
};

const gamesStatic = [
  {
    value: 'Bacc1688',
    label: 'Bacc1688',
    thumb: false,
    code: 'Bacc1688',
  },
  {
    value: 'Sbobet',
    label: 'Sbobet',
    thumb: false,
    code: 'Sbobet',
  },
  {
    value: 'GoldenSlot',
    label: 'GoldenSlot',
    thumb: false,
    code: 'GoldenSlot',
  },
  {
    value: 'Royal1688',
    label: 'Royal1688',
    thumb: false,
    code: 'Royal1688',
  },
  {
    value: 'GoldClub',
    label: 'GoldClub',
    thumb: false,
    code: 'GoldClub',
  },
  {
    value: 'Ruby888',
    label: 'Ruby888',
    thumb: false,
    code: 'Ruby888',
  },
  {
    value: 'Getting Princess Casino',
    label: 'Getting Princess Casino',
    thumb: false,
    code: 'Getting Princess Casino',
  },
  {
    value: 'Reddit88',
    label: 'Reddit88',
    thumb: false,
    code: 'Reddit88',
  },
  {
    value: 'Getting Crown Poppet',
    label: 'Getting Crown Poppet',
    thumb: false,
    code: 'Getting Crown Poppet',
  },
  {
    value: 'SCR888',
    label: 'SCR888',
    thumb: false,
    code: 'SCR888',
  },
  {
    value: 'iBet789',
    label: 'iBet789',
    thumb: false,
    code: 'iBet789',
  },
  {
    value: 'Maxbet',
    label: 'Maxbet',
    thumb: false,
    code: 'Maxbet',
  },
];

const initialValues = {
  games: '',
  username: '',
  email: '',
  phone: '',
  line: '',
  password: '',
  register_type: { real_money: false, free_spin: false },
};

// Checkbox input
const Checkbox = ({ field: { name, value, onChange, onBlur }, id, label }) => (
  <div className="minimal-custom-checkbox">
    <input name={name} id={id} type="checkbox" value={value} checked={value} onChange={onChange} onBlur={onBlur} />
    <label htmlFor={id}>{label}</label>
  </div>
);

// REFACTOR
// At least formik
const FormBody = () => {
  const [selectedGame, setSelectedGame] = useState('');
  const invalidPhoneMsg = 'Invalid phone number Please fill in all 10 digits';

  const handleGameChange = (selectedGame) => {
    setSelectedGame(selectedGame.value);
  };
  return (
    <Wrapper>
      <MsgWrap id="registrationMsgPage">
        <span className="errorMsg" id="registrationErrorMsgPage">
          {dictionary.formSentFailed}
        </span>
        <span className="successMsg" id="registrationSuccessMsgPage">
          {dictionary.formSentSuccessfully}
        </span>
      </MsgWrap>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={(values) => {
          // Stop using lets. Pls. Why you used it?
          const errors = {};
          if (!(values.register_type.real_money || values.register_type.free_spin)) {
            errors.register_type = 'ต้องการอย่างน้อยหนึ่งรายการ';
          }
          return errors;
        }}
        // Create util for it.
        validationSchema={Yup.object().shape({
          username: Yup.string().required(dictionary.formRequiredField),
          email: Yup.string().email().required(dictionary.formRequiredField),
          password: Yup.string().required(dictionary.formRequiredField),
          phone: Yup.string()
            .matches(/^\d+$/g, invalidPhoneMsg)
            .min(10, invalidPhoneMsg)
            .max(10, invalidPhoneMsg)
            .required(dictionary.formRequiredField),
          line: Yup.string().required(dictionary.formRequiredField),
        })}
      >
        {({ errors, status, touched, isSubmitting, isValid, values }) => (
          <Form id="regStaticForm">
            <GamesWrap className="games-wrap">
              <h4>เล่นเกมใหม่ฟรี</h4>
            </GamesWrap>
            <div className="two-fields">
              <div className="fieldWrap iconField">
                <Select
                  options={gamesStatic}
                  placeholder={values.games || 'เลือกเกม'}
                  className="selectGame"
                  value={selectedGame}
                  name="games"
                  styles={{
                    option: (styles) => ({
                      ...styles,
                      backgroundColor: 'unset',
                    }),
                    placeholder: (styles) => ({
                      ...styles,
                      color: selectedGame.trim() !== '' ? '#ffffff!important' : '',
                    }),
                  }}
                  onChange={(selectedOption) => {
                    handleGameChange(selectedOption);
                    values.games = selectedOption.value;
                  }}
                />
                {errors.chooseGame && touched.chooseGame && <div className="error">! {errors.chooseGame}</div>}
              </div>
              <div className="fieldWrap iconField">
                <Field
                  type="text"
                  name="username"
                  placeholder="ยูสเซอร์เนม *"
                  id="register-username-static"
                  value={values.username || ''}
                />
                {errors.username && touched.username && <div className="error">! {errors.username}</div>}
              </div>
            </div>
            <div className="two-fields">
              <div className="fieldWrap iconField">
                <Field
                  type="password"
                  name="password"
                  placeholder="รหัสผ่าน *"
                  value={values.password || ''}
                  autoComplete="on"
                />
                {errors.password && touched.password && <div className="error">! {errors.password}</div>}
              </div>
              <div className="fieldWrap iconField">
                <Field type="text" name="phone" placeholder="Phone *" value={values.phone || ''} />
                {errors.phone && touched.phone && <div className="error">! {errors.phone}</div>}
              </div>
            </div>
            <div className="two-fields">
              <div className="fieldWrap iconField">
                <Field
                  type="email"
                  name="email"
                  placeholder="อีเมล *"
                  id="register-email-static"
                  value={values.email || ''}
                />
                {errors.email && touched.email && <div className="error">! {errors.email}</div>}
              </div>
              <div className="fieldWrap iconField">
                <Field type="text" name="line" placeholder="Line ID *" value={values.line || ''} />
                {errors.line && touched.line && <div className="error">! {errors.line}</div>}
              </div>
            </div>
            <h5 className="small-text">เกมที่คุณสนใจ</h5>

            <Field
              component={Checkbox}
              name="register_type[real_money]"
              id="register-type-real-money-static"
              label="สมัครเล่น สล็อตฟรีสปิน"
              value={values.register_type && values.register_type.real_money ? values.register_type.real_money : false}
            />

            <Field
              component={Checkbox}
              name="register_type[free_spin]"
              id="register-type-free-spin-static"
              label="สมัครเล่นคาสิโน"
              value={values.register_type && values.register_type.free_spin ? values.register_type.free_spin : false}
            />

            {Object.keys(errors).length === 1 && errors.register_type && (
              <div className="error">! {errors.register_type}</div>
            )}

            {status && status.msg && <div>{status.msg}</div>}
            <button
              aria-label="เข้าสู่ระบบ"
              className="button red-button"
              type="submit"
              disabled={isSubmitting || !isValid}
            >
              <span>ยืนยันการสมัคร</span>
            </button>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

const RegistrationFormStatic = ({ games }) => {
  const value = useContext(StoreContext);
  const { username } = value;
  return !username ? (
    <>
      <FormBody games={games} />
    </>
  ) : (
    <h1 align="center">คุณเข้าสู่ระบบแล้ว</h1>
  );
};

export default RegistrationFormStatic;
