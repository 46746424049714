import React from 'react';
import styled from '@emotion/styled';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { color, formStyles, breakpoints, formsAutoFillingStyles } from '../../../utils/style';

const ContactFormWrap = styled('div')`
  width: 50%;
  float: right;
  ${formStyles};
  ${formsAutoFillingStyles};
  @media (max-width: ${breakpoints.lg}) {
    width: 62%;
  }
  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    float: none;
  }
  .loading {
    position: relative;
  }
  .loading::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${color.black};
    opacity: 0.7;
    z-index: 1;
  }
`;

const MsgWrap = styled('div')`
  padding: 5px 10px;
  text-align: center;
  background-color: ${color.yellow};
  color: ${color.black};
  margin-bottom: 30px;
  display: none;
  span {
    display: none;
  }
  &.success {
    .successMsg {
      display: inline;
    }
  }
  &.error {
    .errorMsg {
      display: inline;
    }
  }
`;

const showErrorMsg = () => {
  const msgContainer = document.getElementById('contactMsg');
  msgContainer.classList.add('error');
  msgContainer.style.display = 'block';
  const contactFrom = document.getElementById('contactForm');
  contactFrom.classList.remove('loading');
};

const showSuccessMsg = () => {
  const msgContainer = document.getElementById('contactMsg');
  msgContainer.classList.add('success');
  msgContainer.style.display = 'block';
  const contactFrom = document.getElementById('contactForm');
  contactFrom.classList.remove('loading');
};

const hideMsg = () => {
  const msgContainer = document.getElementById('contactMsg');
  msgContainer.classList.remove('success');
  msgContainer.classList.remove('error');
  msgContainer.style.display = 'none';
  const contactFrom = document.getElementById('contactForm');
  contactFrom.classList.add('loading');
};

// REFACTOR
// Use interpolate for strings like nonceUrl etc. That's much faster and better reading
const onSubmit = (values, { setSubmitting, setErrors, setStatus, resetForm }) => {
  const domain = `${process.env.WP_SOURCE_PROTOCOL}://${process.env.WP_URL_SHORT}`;
  const nonceUrl = `${domain}/wp-json/email-proxi/v2/nonce`;
  const sendUrl = `${domain}/wp-json/email-proxi/v2/send`;
  hideMsg();

  // Promise hell...
  // REFACTOR
  fetch(nonceUrl, {
    method: 'post',
    body: JSON.stringify(values),
  })
    .then((response) => response.text())
    .then((body) => {
      const data = JSON.parse(body);
      // ---------------------------------
      if (data.nonce) {
        // eslint-disable-next-line no-param-reassign
        values.nonce = data.nonce;
        fetch(sendUrl, {
          method: 'post',
          body: JSON.stringify(values),
        })
          .then((response) => response.text())
          .then((body) => {
            const data = JSON.parse(body);

            if (data.success) {
              resetForm({});
              setStatus({ success: true });
              showSuccessMsg();
            } else {
              showErrorMsg();
              setStatus({ success: false });
              setSubmitting(false);
            }
          })
          .catch((error) => {
            showErrorMsg();
            setStatus({ success: false });
            setSubmitting(false);
            setErrors({ submit: error.message });
            // console.log('Send Request failed', error);
            // IMPLEMENT ERROR UTIL
          });
      } else {
        showErrorMsg();
        setStatus({ success: false });
        setSubmitting(false);
        // console.log('Nonce Request failed');
        // IMPLEMENT ERROR UTIL
      }
    })
    .catch(() => {
      showErrorMsg();
      setStatus({ success: false });
      setSubmitting(false);
      // console.log('Nonce Request failed', error);
      // IMPLEMENT ERROR UTIL
    });
};

const initialValues = {
  username: '',
  email: '',
  phone: '',
  message: '',
};

const ContactForm = () => (
  <ContactFormWrap>
    <h2>Contact form</h2>
    <MsgWrap id="contactMsg">
      <span className="errorMsg" id="errorMsg">
        Failed sending form. Please try again
      </span>
      <span className="successMsg" id="successMsg">
        Form successfully sent!
      </span>
    </MsgWrap>

    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        email: Yup.string().email().required('Required'),
        username: Yup.string().required('Required'),
        message: Yup.string().required('Required'),
      })}
    >
      {({ errors, status, touched, isSubmitting, isValid, values }) => (
        <Form id="contactForm">
          <div className="fieldWrap">
            <Field type="text" name="username" placeholder="Name*" value={values.username || ''} />
            {errors.username && touched.username && <div className="error">! {errors.username}</div>}
          </div>
          <div className="fieldWrap">
            <Field type="email" name="email" placeholder="Email*" value={values.email || ''} />
            {errors.email && touched.email && <div className="error">! {errors.email}</div>}
          </div>
          <div className="fieldWrap">
            <Field type="text" name="phone" placeholder="Phone" value={values.phone || ''} />
            {errors.phone && errors.phone && touched.phone && <div className="error">! {errors.phone}</div>}
          </div>
          <div className="fieldWrap">
            <Field component="textarea" name="message" placeholder="Message*" value={values.message || ''} />
            {errors.message && errors.message && touched.message && <div className="error">! {errors.message}</div>}
          </div>
          {status && status.msg && <div>{status.msg}</div>}
          <button type="submit" disabled={isSubmitting || !isValid}>
            Submit
          </button>
        </Form>
      )}
    </Formik>
  </ContactFormWrap>
);
export default ContactForm;
