import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { breakpoints, color, font, sizing } from '../../../utils/style';
import dictionary from '../../../utils/dictionary';

const Wrap = styled('div')`
  padding-right: 30px;
  width: 30%;
  float: left;
  @media (max-width: ${breakpoints.md}) {
    float: none;
    width: 100%;
    margin-bottom: 20px;
    padding-right: 0;
    .textContacts {
      width: 46%;
      display: inline-block;
      vertical-align: top;
      padding-top: 5%;
    }
  }
`;

const BtnWrap = styled('a')`
  width: 340px;
  max-width: 100%;
  display: block;
  margin-bottom: 20px;
`;

const Phone = styled('div')`
  margin-bottom: 10px;
  p {
    margin-bottom: 0;
  }
  a {
    color: ${color.white};
    font-weight: ${font.headingWeight};
    font-size: ${sizing.huge};
  }
  @media (max-width: ${breakpoints.md}) {
    padding: 0;
    p {
      font-size: 10px;
    }
    a {
      font-size: 40px;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    a {
      font-size: 28px;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    p {
      font-size: 9px;
    }
    a {
      font-size: 20px;
    }
  }
`;

const ContactInfo = () => (
  <StaticQuery
    query={graphql`
      query {
        button: file(relativePath: { eq: "lnwasia.png" }) {
          childImageSharp {
            fluid(maxWidth: 353) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={(data) => (
      <Wrap>
        <div className="textContacts">
          <BtnWrap href={dictionary.lineLink}>
            <Img alt="lnwasiagroup" fluid={data.button.childImageSharp.fluid} fadeIn={false} loading="eager" />
          </BtnWrap>
          <Phone>
            <p>ติดต่อสอบถาม สมัครสมาชิก 24 ชม.</p>
            <a href={dictionary.phoneLink}>{dictionary.phoneNumber}</a>
          </Phone>
        </div>
      </Wrap>
    )}
  />
);

export default ContactInfo;
